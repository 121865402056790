.pathExperts {
  fill: none;
  stroke: #d82a2e;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 3s linear forwards 2.5s;
  xxxxxanimation: dash 2s ease-out forwards 3.25s;
}

@media (min-width: 768px) {
  .pathExperts {
    animation: dash 2s ease-out forwards 3.25s;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

:checked + label {
  color: #d8292f;
}

input[type="checkbox"] {
  display: none;
}

.carousel-index .react-multi-carousel-list {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
@media (min-width: 768px) {
  .react-multi-carousel-list {
    border-right: none;
    border-left: none;
  }
}

.react-multi-carousel-track {
  xxxpadding: 0 0 0 20px !important;
  margin: 0 0 0 -1px !important;
}

.index-button-groupxxx {
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
  display: flex;
  align-items: center;
  bottom: 0;
}

.index-button-group {
  width: 100%;
  display: flex;
  align-items: center;
}

.carousel-item-padding-index-px {
  xxxpadding: 0 20px 0 0 !important;
}
.tdgBlue {
  color: #758592 !important;
}

.tobeRed {
  color: #d8292f !important;
}

.introPpw-enter {
  opacity: 0;
}
.introPpw-enter-active {
  opacity: 1;
  transition: opacity 750ms;
}
.introPpw-exit {
  opacity: 1;
}
.introPpw-exit-active {
  opacity: 0;
  transition: opacity 350ms;
}

.carousel-item-padding-40-px {
  padding: 0;
}
.carousel-item-padding-40-px img {
  max-width: 176px;
}
.carousel-item-padding-40-px img {
  max-height: 176px !important;
}
@media (max-width: 767px) {
  .carousel-item-padding-40-px img {
    max-height: 126px !important;
    max-width: 126px !important;
  }
}
.carousel-item-padding-112-px {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-item-padding-112-px img {
  max-width: 250px;
}
.carousel-item-padding-112-px:nth-child(3n + 2) {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.carousel-item-padding-112x-px {
  padding: 0;
}
.carousel-item-padding-112x-px img {
  width: 100%;
  max-width: 233px;
}

.carousel-item-padding-index-px {
  padding: 0;
}
.carousel-item-padding-index-px img {
  max-width: 182px;
}
.carousel-item-padding-index-px img {
  max-height: 182px !important;
}
@media (max-width: 767px) {
  .carousel-item-padding-index-px img {
    max-height: 143px !important;
    max-width: 143px !important;
  }
}

/*

<style>
  body {overflow: hidden;}
  iframe.showNav {top: 60px!important;}
  .carousel-button-group {display:none!important;}
</style>  

<iframe class="showNav" 
*/
.loadedIdeaCard {
  opacity: 0;
  margin-top: 15px;
  transition: all ease 250ms;
  transition-delay: 100ms;
}

.ideaContainer {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  grid-template-columns: none;
}
@media (min-width: 768px) {
  .ideaContainer {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1024px) {
  .ideaContainer {
    column-gap: 45px;
    row-gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1600px) {
  .ideaContainer {
    column-gap: 60px;
    row-gap: 60px;
  }
}

.menuButton {
  height: 56px;
  position: fixed;
  right: 24px;
  top: 0;
  width: 27px;
  z-index: 999992;
  display: flex;
  align-items: center;
}

.containerx {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar3 {
  width: 27px;
  height: 1px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
}

.invis {
  width: 27px;
  height: 1px;
  margin: 3px 0;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-2.5px, 3px);
  transform: rotate(-45deg) translate(-2.5px, 3px);
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-2.5px, -3px);
  transform: rotate(45deg) translate(-2.5px, -3px);
}

* {
  box-sizing: border-box;
  outline: none;
}
html {
  /* scroll-behavior: smooth; */
}

nav {
  font-weight: 300;
  font-style: normal;
}
body {
  font-family: soleil, sans-serif;
  font-variant-numeric: lining-nums;
  background: #f8f8f8;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1 {
  font-family: freight-big-pro, serif;
  font-weight: 300;
  font-style: normal;
  font-size: 40px;
  padding: 0;
  margin: 0;
}

p {
  font-weight: 300;
  font-style: normal;
  margin: 0 0 1em;
}

a {
  color: #d8292f;
}
img {
  width: 100%;
}
.img {
  opacity: 0;
  clip-path: inset(0);
  transition: all 500ms;
  -webkit-transition: all 500ms; /* Safari */
}
.img.loaded {
  opacity: 1;
  filter: blur(0);
}

.prePayActive {
  color: #d8292f !important;
}

.mottoWord {
  animation: color-change 3.5s;
}

.mottoWord--blue {
  animation: color-change-blue 3.5s;
  margin-left: 0.25em;
}

.mottoWord--1 {
  animation-delay: 0.5s;
}
.mottoWord--2 {
  animation-delay: 1s;
}
.mottoWord--3 {
  animation-delay: 1.5s;
}
.mottoWord--4 {
  animation-delay: 2s;
}
.mottoWord--5 {
  animation-delay: 2.5s;
}
.mottoWord--6 {
  animation-delay: 3s;
}
.mottoWord--7 {
  animation-delay: 3.5s;
}
.mottoWord--8 {
  animation-delay: 4s;
}
.mottoWord--9 {
  animation-delay: 4.5s;
}

.mottoWord--plus {
  margin: 0 1em;
}

@-webkit-keyframes color-change {
  0% {
    color: black;
  }
  14.99% {
    color: black;
  }
  15% {
    color: #d8292f;
  }
  30.99% {
    color: #d8292f;
  }
  31% {
    color: black;
  }
  100% {
    color: black;
  }
}

@keyframes color-change {
  0% {
    color: black;
  }
  14.99% {
    color: black;
  }
  15% {
    color: #d8292f;
  }
  30.99% {
    color: #d8292f;
  }
  31% {
    color: black;
  }
  100% {
    color: black;
  }
}

@-webkit-keyframes color-change-blue {
  0% {
    color: black;
  }
  14.99% {
    color: black;
  }
  15% {
    color: #758592;
  }
  30.99% {
    color: #758592;
  }
  31% {
    color: black;
  }
  100% {
    color: black;
  }
}

@keyframes color-change-blue {
  0% {
    color: black;
  }
  14.99% {
    color: black;
  }
  15% {
    color: #758592;
  }
  30.99% {
    color: #758592;
  }
  31% {
    color: black;
  }
  100% {
    color: black;
  }
}

@keyframes ZoomIn {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ZoomOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-300%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-enter {
  opacity: 0;
  transition: all ease 1s;
  position: absolute;
  top: 0;
  right: -100px;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all ease 1s;
  transform: translateX(0);
  position: absolute;
  top: 0;
  right: 0;
}

.fade-exit {
  opacity: 1;
  transition: all ease 3s;
  position: absolute;
  right: 0;
}

.fade-exit.fade-exit-active {
  opacity: 7;
  transition: all ease 3s;
  position: absolute;
  top: 0;
  right: 2000px;
}
@media (min-width: 1280px) {
  .fade-exit.fade-exit-active {
    right: 3000px;
  }
}
@media (min-width: 1800px) {
  .fade-exit.fade-exit-active {
    right: 4000px;
  }
}

.whiteOut-enter {
  opacity: 0;
  transition: all 0.2s ease;
  transition-delay: 500ms;
}

.whiteOut-enter.whiteOut-enter-active {
  opacity: 1;
  transition: all 0.2s ease;
}

.whiteOut-exit {
  opacity: 1;
  transition: all 0.2s ease;
}

.whiteOut-exit.whiteOut-exit-active {
  opacity: 1;
  transition: all 0.2s ease;
}

.fade2-enter {
  opacity: 0;
}
.fade2-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.fade2-exit {
  opacity: 1;
}
.fade2-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

.menuMobile-enter {
  opacity: 0;
  display: block !important;
}
.menuMobile-enter-active {
  opacity: 1;
  transition: opacity 250ms;
  display: block !important;
}
.menuMobile-enter-done {
  opacity: 1 !important;
  transition: opacity 250ms;
  display: grid !important;
}

.menuMobile-exit {
  opacity: 1;
  display: grid !important;
}
.menuMobile-exit-active {
  opacity: 1;
  transition: opacity 250ms;
  display: grid !important;
}
.menuMobile-exit-done {
  opacity: 0 !important;
  transition: opacity 250ms;
  display: none !important;
}

.mobileItem-enter {
  opacity: 0;
  left: -50px;
}
.mobileItem-enter-active {
  opacity: 1;
  left: 0;
  transition: all 250ms;
}

.mobileItem-exit {
  opacity: 1;
}
.mobileItem-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

.intelTxt-enter {
  opacity: 0;
}
.intelTxt-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}
.intelTxt-exit {
  opacity: 1;
}
.intelTxt-exit-active {
  opacity: 0;
  transition: opacity 250ms 200ms;
}

.howWeDo-enter {
  opacity: 0;
}
.howWeDo-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
  transition-delay: 300ms;
}
.howWeDo-exit {
  opacity: 1;
}
.howWeDo-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.howWeDoSlide-enter {
  opacity: 0;
  scale: 0.5;
}
.howWeDoSlide-enter-active {
  opacity: 1;
  scale: 1;
  transition: all 250ms ease-in;
}
.howWeDoSlide-exit {
  opacity: 1;
}
.howWeDoSlide-exit-active {
  opacity: 0;
  scale: 0.5;
  transition: all 150ms ease-out;
}

.newsFade-enter {
  opacity: 0;
}
.newsFade-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}
.newsFade-exit {
  opacity: 1;
}
.newsFade-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

.custom__link {
  color: #fff;
  text-align: center;
  letter-spacing: 0.84px;
  font-family: gopher, sans-serif;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}

.xxxreact-multi-carousel-track {
  z-index: 10000;
}

.travelGuides {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.travelGuides > div {
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 0 -1px -1px 0;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.travelGuides div a {
  text-decoration: none;
  color: hsla(0, 0%, 0%, 0.9);
}
.travelGuide iframe {
  width: 100%;
  height: 70vh;
}

.creativeDirections {
  display: grid;
  grid-template-columns: 50% 50%;
}

.creativeDirections > div {
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 0 -1px -1px 0;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.pagination {
  display: inline;
  padding: 0;
  margin: 0;
}

.pagination__page,
.pagination .previous,
.pagination .next {
  margin: 0 0.75em;
  cursor: pointer;
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.pagination__page {
  font-size: 14px;
}
.pagination > * {
  display: inline-block;
}
.pagination > *:hover {
  opacity: 0.6;
}
.pagination .active {
  border-bottom: 1px solid red;
}
.pagination__break {
  margin: 0 0.75em;
}

.pagination__break:hover {
  opacity: 1;
}

.contact-modal-enter {
  opacity: 0;
}
.contact-modal-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.contact-modal-exit {
  opacity: 1;
}
.contact-modal-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/* This fires as soon as the element enters the dorm */
.fade-transition-enter,
.fade-transition-appear {
  /*We give the list the initial dimension of the list button*/
  opacity: 0;
  transform: translateY(20px);
}
/* This is where we can add the transition*/
.fade-transition-enter-active,
.fade-transition-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 200ms ease-in;
}

/* This fires as soon as the this.state.showList is false */
.fade-transition-exit {
  opacity: 1;
  transform: translateY(0);
}
/* fires as element leaves the DOM*/
.fade-transition-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: all 200ms ease-in;
}

.list-transition-default {
  background: pink;
}
/* This fires as soon as the element enters the dorm */
.list-transition-enter,
.list-transition-appear {
  /*We give the list the initial dimension of the list button*/
  opacity: 0;
  transform: translateY(-20px);
}
/* This is where we can add the transition*/
.list-transition-enter-active,
.list-transition-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 200ms ease-in;
}

/* This fires as soon as the this.state.showList is false */
.list-transition-exit {
  opacity: 1;
  transform: translateY(0);
}
/* fires as element leaves the DOM*/
.list-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 200ms ease-in;
}
